import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HiArrowNarrowRight } from "react-icons/hi";
import { AiOutlineCheck } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { Logo } from "../images";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  let x = window.location.pathname;
  return (
    <>
      {/* <section className="subscribe-section centred">
        <Container>
          <Row>
            <Col sm="12" className="text-center subscribe-icon">
              <SlEnvelopeOpen />
              <h2>Subscribe Our Newsletter</h2>
              <p>
                Subscribe our newsletter and get latest news, updates <br />
                to your inbox directly.
              </p>
            </Col>
          </Row>
          <Row>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <FaRegUser />
                <input type="text" name="name" placeholder="Your Name" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="form-group">
                <HiOutlineMail />
                <input type="text" name="name" placeholder="Your Name" />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="subscribe-btn">
                <button>
                  <HiArrowNarrowRight />
                  Subscribe
                </button>
              </div>
            </div>
          </Row>
        </Container>
      </section> */}
      <div className="main-footer">
        {x === "/contact-us" || x === "/careers" ? (
          <></>
        ) : (
          <Container>
            <Row>
              <Col sm="12">
                <div className="quote-sec d-flex align-items-center justify-content-between">
                  <div>
                    <h3>LOOKING FOR SUPPORT?</h3>
                    <p>We stand ready to support you in any way necessary!</p>
                  </div>
                  <div className="subscribe-btn">
                    <button onClick={() => navigate("/contact-us")}>
                      <HiArrowNarrowRight />
                      Get a Quote
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
        <Container style={{ position: "relative" }}>
          <Row>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="footer-logo">
                <img src={Logo} alt="" />
              </div>
              {/* <div className="support-box">
                <HiOutlineMail />
                <h6>Free Consult</h6>
                <a href="/">support@info.com</a>
              </div> */}
              <div className="text">
                <h3>Motiv Us</h3>
                <p>
                  Motivus is a professional tech company based in Noida serving
                  clients across India.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="links-widget link-extra">
                <div className="widget-title">
                  <h5>Contact Us</h5>
                </div>
                <div className="widget-content">
                  <p>
                    <FiPhoneCall />
                    <Link to={'tel:O1204424713'}> O1204424713</Link>
                  </p>
                  <ul className="links-list">
                    <li>
                      <BsFillEnvelopeFill />
                      <Link to={'mailto:info@motivusi.com'}>info@motivusi.com</Link>
                    </li>
                    <li>
                      <SlLocationPin />
                      <Link href="/">G-9 , Block – H-25 , Sector -63</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="links-widget">
                <div className="widget-title">
                  <h5>Quick Links</h5>
                </div>
                <div className="widget-content mobsvg">
                  <ul className="links-list">
                    <li>
                      <AiOutlineCheck className="fa-fade" />
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <AiOutlineCheck />
                      <Link to="/products">Products</Link>
                    </li>
                    <li>
                      <AiOutlineCheck />
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <AiOutlineCheck />
                      <Link to="/careers">Career</Link>
                    </li>
                    <li>
                      <AiOutlineCheck />
                      <Link to="/contact-us">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Row>
          <div className="row"><div class="col-sm-12"><p className="copyright-text">©2023 All Rights Reserved <a href="/"> Motivus </a> |  Designed and developed by <a href="https://kyleinfotech.co.in/" target="_blank">KYLE INFOTECH</a></p></div></div>
        </Container>
      </div>
      
      {/* <div className="footer-bottom">
        <Container>
          <Row>
            <Col sm="12">
              <div className="copyright pull-left">
                <p>
                  Copyright &copy; 2023{" "}
                  <a target="_blank" href="https://kyleinfotech.co.in/">
                    Kyle infotech pvt Ltd
                  </a>{" "}
                  . All Rights Reserved.
                </p>
              </div>
              <ul className="footer-nav pull-right">
                <li>
                  <a href="/">Terms & Conditions</a>
                </li>
                <li>
                  <a href="/">Privacy Policy</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default Footer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { SlEnvelopeOpen } from "react-icons/sl";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import apiClient from "../Config/apiConfig";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { VisibleError } from "../Components";

const Careers = () => {
  const [loader, setLoader] = useState(false);
  const [careersData, setCareersData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    designation: "",
    attachment: "",
  });
  const [error, setError] = useState({
    fullNameError: false,
    emailError: false,
    phoneNumberError: false,
    designationError: false,
    attachmentError: false,
  });
  const { fullName, email, phoneNumber, designation, attachment } = careersData;
  const {
    fullNameError,
    emailError,
    phoneNumberError,
    designationError,
    attachmentError,
  } = error;
  const handleChange = (e) => {
    if (e?.target?.name) {
      setCareersData((data) => {
        return {
          ...data,
          [e.target.name]: e.target.value,
        };
      });
    } else {
      setCareersData((data) => {
        return {
          ...data,
          attachment: e.target.files[0],
        };
      });
    }
  };
  const contactFromSubmit = async (e) => {
    e.preventDefault();
    let check = false;
    if (fullName.trim() === "") {
      setError((old) => {
        return {
          ...old,
          fullNameError: true,
        };
      });
      check = true;
    }
    if (email.trim() === "") {
      setError((old) => {
        return {
          ...old,
          emailError: true,
        };
      });
      check = true;
    }
    if (phoneNumber.trim() === "") {
      setError((old) => {
        return {
          ...old,
          phoneNumberError: true,
        };
      });
      check = true;
    }
    if (designation.trim() === "") {
      setError((old) => {
        return {
          ...old,
          designationError: true,
        };
      });
      check = true;
    }
    if (attachment.trim() === "") {
      setError((old) => {
        return {
          ...old,
          attachmentError: true,
        };
      });
      check = true;
    }
    if (check) {
      return;
    }
    try {
      setLoader(true);
      const res = await apiClient.upload(`api/createCareers`, {
        fullName,
        email,
        phoneNumber,
        designation,
        attachment,
      });
      console.log(res);
      if (res.data.status === "200") {
        toast.success(res.data.success);
        setCareersData({
          fullName: "",
          email: "",
          phoneNumber: "",
          designation: "",
          attachment: "",
        });
      } else if (res.data.status === "405") {
        toast.warning(res.data.error);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>Careers</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>Careers</li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="service-section contact-section">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-subtitle">WANT QUICK HELP?</h4>
              <h2 className="pbmit-title">Your Path to Success</h2>
              <div className="pbmit-heading-desc">
                Discover and Unlock Your Full Potential with Our Range of
                Exciting and Rewarding Career Opportunities
              </div>
            </div>
          </Row>
          <Row className="pb-4">
            <Col lg="4" md="6" sm="12">
              <div className="contact-box d-flex">
                <div className="contact-box-icon">
                  <FaMapMarkedAlt />
                </div>
                <div className="contact-box-content">
                  <h2>Our Location​​</h2>
                  <p>G-9 , Block – H-25 , Sector -63 , Noida Pin -201013.</p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="contact-box d-flex">
                <div className="contact-box-icon">
                  <FiPhoneCall />
                </div>
                <div className="contact-box-content">
                  <h2>Phone Number</h2>
                  <p>
                    <Link to={'tel:0120-3101497'}>0120-3101497. (24/7)</Link>
                    <br />
                    <Link to={'tel:+917004645883'}>+91 7004645883. (24/7)</Link>
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="6" sm="12">
              <div className="contact-box d-flex">
                <div className="contact-box-icon">
                  <SlEnvelopeOpen />
                </div>
                <div className="contact-box-content">
                  <h2>Email Address​</h2>
                  <Link to={'mailto:info@motivusi.com'}>
                    info@motivusi.com
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-5">
          <Row>
            <Col sm="12">
              <div className="form-inner">
                <Row className="mb-3 g-3">
                  <Col lg="6" md="6">
                    <label className="mb-2">Full Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Full Name"
                      name="fullName"
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            fullNameError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={fullNameError} name="Full Name" />
                  </Col>
                  <Col lg="6" md="6">
                    <label className="mb-2">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Your Email"
                      name="email"
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            emailError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={emailError} name="Email" />
                  </Col>
                  <Col lg="6" md="6">
                    <label className="mb-2">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Phone Number"
                      name="phoneNumber"
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            phoneNumberError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={phoneNumberError} name="Phone Number" />
                  </Col>
                  <Col lg="6" md="6">
                    <label className="mb-2">Designation</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Designation"
                      name="designation"
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            designationError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={designationError} name="Designation" />
                  </Col>
                  <Col lg="6" md="6">
                    <label className="mb-2">Upload Your CV</label>
                    <input
                      type="file"
                      // accept="pdf"
                      className="form-control"
                      onChange={(e) => {
                        handleChange(e);
                        setError((old) => {
                          return {
                            ...old,
                            attachmentError: false,
                          };
                        });
                      }}
                    />
                    <VisibleError show={attachmentError} name="CV" />
                  </Col>
                  <Col lg="12">
                    <div className="header-button mt-1">
                      <Link disabled={loader} onClick={contactFromSubmit}>
                        {loader ? (
                          <div className="d-flex justify-content-center">
                            <TailSpin height="25" width="25" color="#fff" />
                          </div>
                        ) : (
                          <>
                            submit <HiOutlineArrowNarrowRight />
                          </>
                        )}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Careers;

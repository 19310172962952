import React from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import Products from "../Components/Products";
import { FaUserTie } from "react-icons/fa";
import { Slider, Branches } from "../Components";
import OwlCarousel from "react-owl-carousel";
import {
  AboutUsImg,
  ServiceImg,
  WhoWeAre,
  Years,
  MotivusAboutusHome,
  Certifications,
  CertificationsOne,
  CertificationsTwo,
  CertificationsThree,
  CertificationsFour,
  CertificationsFive,
  CertificationsSix,
  Association1,
  Association2,
  Association3,
  Association4,
  Association5,
  Association6,
  Supportingus1,
  Supportingus2,
  Supportingus3,
  Supportingus4,
  Supportingus5,
  OURCHALLENGES,
  BatteryImg,
  SMPS,
  Digi,
  Under,
  Blind,
  VR,
  VRLA,
  MotivusAboutusInnerPage
} from "../images";
import { Link } from "react-router-dom";

const Home = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    loop: true,
    dots: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 1,
      },
      1024: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };
  const clients = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    autoplay: false,
    loop: true,
    dots: false,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 2,
      },
      430: {
        items: 2,
      },
      600: {
        items: 3,
      },
      700: {
        items: 4,
      },
      1024: {
        items: 6,
      },
    },
  };
  return (
    <>
      <Slider />
      <div className="about-sec">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="about-img">
                <img src={MotivusAboutusHome} alt="" />
                {/* <img className="years" src={Years} alt="" /> */}
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="about_content">
                <h3 className="heading">
                  <span>About Us</span>
                </h3>
                
                <p>
                Motivus Innovation Private Limited as a technology innovation company. Head Quartered in Noida we serve customers Pan India. A highly professional management, with a collective experience of more than 150 years in various industries spanning Telecommunications, Renewable Energy, Power Electronics, Fibre, and Transmission, in India as well as other South East Asian countries, like Malaysia, Myanmar, Bangladesh, Pakistan, Sri Lanka, Cambodia, Philippines, Indonesia and Thailand.
                </p>
               
                <Link to="about-us">Read More</Link>
                <h1 className="sub-heading">
                  <span>About</span>
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="service-section branches-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">Our Services</h4>
              <p>The company is involved in identifying operational challenges in various industries and providing Innovative Solutions. We have the following solutions in our portfolio currently:</p>
            </div>
          </Row>
          <Row>
            <OwlCarousel className="slider-items owl-carousel owl-home" {...options}>
              <div className="item">
                <Products image={BatteryImg} title={"Battery Regeneration"} />
              </div>
              <div className="item">
                <Products
                  image={Digi}
                  title={"Digital Battery Regeneration"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={SMPS}
                  title={"SMPS Core Upgradation"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }  
                />
              </div>
              <div className="item">
                <Products
                  image={Under}
                  title={"Underground DG Bunkers"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={VRLA}
                  title={"Battery Management System for VRLA Batteries"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={Blind}
                  title={"IOT Based Blind Sticks and Wheel Chairs"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={VR}
                  title={"VR Gear based Training Modules"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
              <div className="item">
                <Products
                  image={MotivusAboutusInnerPage}
                  title={"Fleet Management Systems"}
                  // desc={
                  //   "There are many variations of pass ages of Lorem Ipsum available but the majority."
                  // }
                />
              </div>
            </OwlCarousel>
          </Row>
        </Container>
      </div>
      <div className="statements-section">
        <Container>
          <Row>
            <Col sm="12">
              <div className="tab-sec">
                <Tabs defaultActiveKey="Mission" id="uncontrolled-tab-example">
                  <Tab eventKey="Mission" title="Mission statements">
                    <Row className="justify-content-end">
                      <Col md="12" lg="6">
                        <div className="content-box">
                          <h5>Mission Statement</h5>
                          {/* <h3>Company wants to achieve</h3> */}
                          <p>
                          To be the most preferred Solutions Provider, by offering innovative, comprehensive and customized solutions, services & products in the domain of Power, Infrastructure and Software.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="Vision" title="Vision statements">
                    <Row className="justify-content-end">
                      <Col md="12" lg="6">
                        <div className="content-box">
                          <h5>Vision Statement</h5>
                          {/* <h3>Company wants to achieve</h3> */}
                          <p>
                          To constantly innovate, build and deliver superior technological solutions for industry challenges that offer the highest level of reliability, efficiency & safety.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey="Value" title="CORE VALUES">
                    <Row className="justify-content-end">
                      <Col md="12" lg="6">
                        <div className="content-box">
                          <h5>CORE VALUES</h5>
                          {/* <h3>Company wants to achieve</h3> */}
                          <p><i><strong class="color-logo">Ability to Listen: </strong></i>Motivus believes in keeping the ears open to listen to the issues plaguing the industries and identifying gaps.</p>
                          <p><i><strong class="color-logo">Innovation: </strong></i>Motivus constantly innovates to find out solutions to the challenges and deliver on timely manner to customers.</p>
                          <p><i><strong class="color-logo">Integrity: </strong></i>Motivus ensures fairness, honesty and ethical behaviour in all that we do towards all of our stakeholders.</p>
                          <p><i><strong class="color-logo">Excellence: </strong></i>Motivus always pursues excellence and promotes the best standard of quality possible.</p>
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="service-section association-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">OUR Supporting Us</h4>
            </div>
          </Row>
          <Row>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Supportingus1} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus2} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus3} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus4} alt="Supporting Us" />
                </div>
                <div className="item">
                  <img src={Supportingus5} alt="Supporting Us" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="service-section">
        <Branches />
      </div>

      <div className="service-section association-sec">
        <Container>
          <Row>
            <div className="pbmit-heading-subheading center-align text-center">
              <h4 className="pbmit-title">OUR Association</h4>
            </div>
          </Row>
          <Row>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Association1} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association2} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association3} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association4} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association5} alt="Association Logo" />
                </div>
                <div className="item">
                  <img src={Association6} alt="Association Logo" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="who-we-are pt-5">
        <Container>
        
          <h3 className="mb-5 text-white text-center fw-bold fs-1">Brief Introduction of Management</h3>
                
          <Row>
            <Col lg="4" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>DIPANJAN MUKHERJEE</h5>
                  
                  <p>
                  Over 26 years’ of professional experience in Telecom and Power Sector, of which the last 15 years at CXO/Director levels. Has experience of managing live network in India and 9 other countries in South East Asia.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>RAVI SHANKAR</h5>
                  
                  <p>
                  Over 25 years’ of professional experience in Telecom and Power Sector, with reputed organizations like Aircel, Bharti Infratel, Exicom etc. Last 4 years was Operations Head for Edotco Group, in Myanmar, which is globally the 6th largest Tower Company.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg="4" md="12">
              <div className="working-block-three">
                <div className="inner-box">
                  <FaUserTie />
                  <h5>PREETI VARSHNEY</h5>
                  
                  <p>
                  Over 15 years’ experience in Quality Control, Audit and Manufacturing. She is a Six Signa Blackbelt from South Korea. She has worked with large companies like Samsung and Uniworld.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="service-section challenges">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="img-box">
                <img src={OURCHALLENGES} alt="" />
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="pbmit-heading-subheading">
                <h4 className="pbmit-subtitle">OUR Challenges</h4>
                <h2 className="pbmit-title">
                  The challenges faced by the facility management are quite
                  significant.
                </h2>
              </div>
              <div className="challenges-content">
                <ul>
                  <li>The tenant has high power requirements</li>
                  <li>
                    99.999% uptime requirement, with no outages acceptable.
                  </li>
                  <li>High cost per GB of data generation.</li>
                  <li>
                    Frequent replacement CAPEX needed for lead-acid batteries.
                  </li>
                  <li>
                    No cell-level monitoring available for lead-acid batteries
                    leading to large-scale abuse and pilferage.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="certifications-sec">
        <Container className="borders-sec">
          <Row>
            <Col sm="12">
              <h2>Certifications</h2>
            </Col>
            <Col sm="12">
              <OwlCarousel
                className="slider-items owl-carousel certifications-logo"
                {...clients}
              >
                <div className="item">
                  <img src={Certifications} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsOne} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsTwo} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsThree} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsFour} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsFive} alt="" />
                </div>
                <div className="item">
                  <img src={CertificationsSix} alt="" />
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="fun-section centred green-bg">
        <span className="big-text">Fun Numbers</span>
        <div className="bg-layer">
          <div className="bg-1"></div>
          <div className="bg-2"></div>
        </div>
        <Container>
          <Row>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>2600</span>
                </div>
                <h5>Projects Completed</h5>
                <div className="icon-box">
                  <GiFilmProjector />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>137</span>
                </div>
                <h5>Professional Staff</h5>
                <div className="icon-box">
                  <FaUserTie />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>09</span>
                </div>
                <h5>Countries Covered</h5>
                <div className="icon-box">
                  <FaGlobeAmericas />
                </div>
              </div>
            </Col>
            <Col lg="3" md="6" sm="12">
              <div className="inner-box">
                <div className="count-outer">
                  <span>845</span>
                </div>
                <h5>Satisfied People</h5>
                <div className="icon-box">
                  <FaPeopleArrows />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default Home;

import "./App.css";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home";
import ContactUs from "./Pages/ContactUs";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AboutUs from "./Pages/AboutUs";
import Gallery from "./Pages/Gallery";
import Careers from "./Pages/Careers";
import Products from "./Pages/Products";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="products" element={<Products />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="careers" element={<Careers />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { AboutUsImg, Years,MotivusAboutusInnerPage } from "../images";

const AboutUs = () => {
  return (
    <>
      <div className="title-bar-wrapper">
        <Container>
          <Row>
            <div className="title">
              <h1>About Us</h1>
            </div>
            <ul className="bread-crumb pull-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>-</li>
              <li>About</li>
            </ul>
          </Row>
        </Container>
      </div>
      <div className="about-sec" style={{ padding: "70px 0px 130px" }}>
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="about-img">
                <img src={MotivusAboutusInnerPage} alt="" />
                {/* <img className="years" src={Years} alt="" /> */}
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="about_content">
                <h3 className="heading">
                  <span>About Us</span>
                </h3>
                {/* <h2>Driving Change As A Disruptor</h2> */}
                <p>
                Motivus Innovation Private Limited is a company dedicated to technological innovation and is based in Noida, India. Our services are available to clients throughout the entire country. Our team is composed of highly professional individuals with a combined experience of more than 150 years in various industries. These industries include, but are not limited to, Telecommunications, Renewable Energy, Power Electronics, Fibre, and Transmission. Our expertise extends beyond India to other South East Asian countries such as Malaysia, Myanmar, Bangladesh, Pakistan, Sri Lanka, Cambodia, Philippines, Indonesia, and Thailand. Through our experience in these regions, we have developed a deep understanding of the challenges and opportunities presented in these markets, which allows us to provide tailored and effective solutions to our clients.
                </p>
                
                <h1 className="sub-heading">
                  <span>About</span>
                </h1>
              </div>
            </Col>
            
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
